<template>
	<div :class="loaderType" v-if="modifier !== 'space'">
		<div class="card-skeleton__fig">
			<div class="card-skeleton__img"></div>
		</div>
		<div class="card-skeleton__content">
			<div class="card-skeleton__date"></div>
			<div class="card-skeleton__read"></div>
			<div class="card-skeleton__title"></div>
			<div class="card-skeleton__text"></div>
			<div class="card-skeleton__link"></div>
		</div>
	</div>
	<div :class="loaderType" v-else></div>
</template>

<script>
export default {
	name: 'Card-Skeleton',
	props: {
		modifier: String
	},
	data() {
		return {
			blockName: 'card-skeleton'
		}
	},
	computed: {
		loaderType() {
			let className = this.blockName;
			return (this.modifier) ? `${this.blockName} ${this.blockName}--${this.modifier}` : className;
		}
	}
}
</script>

<style lang="scss" scoped>
.card-skeleton {
	$self: &;
	background-color: #333;
	border-radius: 14px;

	&__content {
		display: grid;
		gap: 10px;
		grid: {
			template-areas:
				'date read'
				'title title'
				'text text'
				'. link';
		}
		padding: 15px;
	}

	&__date,
	&__img,
	&__link,
	&__read,
	&__text,
	&__title {
		animation: skeleton-loading 1s linear infinite alternate;
		background-color: #ccc;
		opacity: .5;
	}

	&__date,
	&__link,
	&__read {
		height: 10px;
	}

	&__date {
		grid-area: date;
	}

	&__fig {
		align-items: center;
		display: flex;
		height: 234px;
		justify-content: center;
	}

	&__img {
		border-radius: 50%;
		height: 140px;
		width: 140px;
	}

	&__link {
		grid-area: link;
	}

	&__read {
		grid-area: read;
	}

	&__text {
		grid-area: text;
		height: 28px;
	}

	&__title {
		grid-area: title;
		height: 20px;
	}

	&--space {
		height: 50px;
		margin: 30px 0;
	}

	@media (width >= 768px) {
		&--featured {
			display: grid;
			grid-template: {
				areas:
					'content image';
				columns: 60% 40%;
			}

			#{$self} {
				&__content {
					grid-template: {
						areas:
							'title title'
							'date read'
							'text text'
							'. link';
					}
					grid-area: content;
					padding: 36px 0 36px 20px;
				}

				&__fig {
					grid-area: image;
				}

				&__text {
					height: 100px;
				}
			}
		}
	}

	@keyframes skeleton-loading {
		0% {
			background-color: #333;
		}

		100% {
			background-color: #ccc;
		}
	}
}
</style>

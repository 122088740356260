import {createStore} from 'vuex'

export default createStore({
    state: {
        menuState: false,
        posts: []
    },
    getters: {
        menuState(state) {
            return state.menuState;
        },
        posts(state) {
            return state.posts;
        }
    },
    mutations: {
        setMenuState(state, status) {
            state.menuState = status;
        },
        setPosts(state, status) {
            state.posts = status;
        }
    },
    actions: {
        async getPosts({commit}) {
			const postLimit = 10;
            const settings = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
					'x-api-key': process.env.VUE_APP_API_KEY,
                }
            };

            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/?limit=${postLimit}`, settings);
                const data = await response.json();

				commit('setPosts', JSON.parse(data.body));
            } catch(err) {
				console.log('Could not get posts');
                throw err;
            }
        },
        menuToggle({commit}) {
            commit('setMenuState', !this.state.menuState);
        }
    },
    modules: {}
});

<template>
    <nav class="menu-drawer" :class="modifier">
        <ul class="menu-drawer__list">
            <li class="menu-drawer__list-item">
                <router-link class="menu-drawer__link" to="/" @click="menuToggle">Home</router-link>
            </li>
            <li class="menu-drawer__list-item">
                <router-link class="menu-drawer__link" to="/about" @click="menuToggle">About</router-link>
            </li>
			<li class="menu-drawer__list-item" v-if="isEnv === 'development'">
				<router-link class="menu-drawer__link" to="/post-new" @click="menuToggle">Post New</router-link>
			</li>
        </ul>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'MenuDrawer',
	data() {
		return {
			isEnv: process.env.NODE_ENV
		}
	},
    computed: {
        ...mapGetters(['menuState']),
        modifier() {
            return this.menuState ? 'menu-drawer--open' : '';
        }
    },
    methods: {
        ...mapActions(['menuToggle'])
    }
}
</script>

<style lang="scss" scoped>
.menu-drawer {
    align-items: center;
    background-color: var(--shark);
    display: flex;
    height: 0;
    justify-content: center;
    overflow: hidden;
    transition: all .2s ease;

    &__link {
        color: #aeaeae;
        font: {
            weight: var(--weight-medium);
            size: 1.4rem;
        }
		letter-spacing: .5px;

		@media (width >= 768px) {
			font-size: 1.5rem;
		}

		@media (width >= 992px) {
			font-size: 1.6rem;
		}
    }

    &__list {
        display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media (width >= 768px) {
			flex: 100%;
		}

        &-item {
			flex: 0 0 calc(50% - 20px);
            //padding: 0 8px;
			margin: 5px;
			text-align: center;

			@media (width >= 768px) {
				flex: 0 0 calc(17% - 20px);
			}
        }
    }

    &--open {
        height: 60px;
        transition: all .2s ease;
    }
}
</style>

<template>
    <Header/>
	<!--<Search placeholder-text="Find Articles..."/>-->
    <div class="app__wrap">
        <main class="app__main">
            <router-view/>
        </main>
    </div>
    <Footer/>
</template>

<style lang="scss">
@import '@/styles/vars.scss';
@import '@/styles/reboot.scss';
@import '@/styles/global.scss';

.app {
	display: grid;
	grid-template: {
		columns: minmax(290px, 1fr);
		rows: auto auto 1fr auto;
	}
	min-height: 100vh;

    &__main {
        padding-top: 30px;
    }

    &__wrap {
        padding: 0 20px;
    }

	@media (width >= 1200px) {
		max-width: 1200px;
		margin: 0 auto;
	}
}
</style>

<template>
    <div class="menu-toggle" :class="modifier" @click="menuToggle">
        <div class="menu-toggle__wrap"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'MenuToggle',
    computed: {
        ...mapGetters(['menuState']),
        modifier() {
            return this.menuState ? 'menu-toggle--open' : '';
        }
    },
    methods: {
        ...mapActions(['menuToggle'])
    }
}
</script>

<style lang="scss" scoped>
.menu-toggle {
    $self: &;
    align-items: center;
    background: var(--bg-primary);
	cursor: pointer;
    display: flex;
    height: 46px;
    justify-content: center;
    width: 60px;

    &__wrap {
        border: 2px solid var(--dusty-gray) {
            left: 0;
            right: 0;
        };
        height: 10px;
        transition: all .2s ease;
        width: 18px;
    }

    &--open {
        #{$self}__wrap {
            transform: rotate(-90deg);
            transition: all .2s ease-out;
        }
    }
}
</style>

<template>
    <h1 class="brand">
		<router-link class="brand__link" to="/">
			<img class="brand__img" src="../assets/logo.svg" :alt="altAttr" width="120">
		</router-link>
    </h1>
</template>

<script>
export default {
    name: 'Brand',
	data() {
		return {
			altAttr: `${process.env.VUE_APP_TITLE} logo is silver colored with varying typeface weights for style`
	}
	}
}
</script>

<style lang="scss" scoped>
.brand {
    padding-left: 20px;

	&__img {
		width: 140px;
	}
}
</style>

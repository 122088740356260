<template>
    <footer class="footer">
        <small class="footer__text">Copyright &copy; {{ setCurrentYear }} Ardent Forms. All Rights Reserved.</small>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: null
        };
    },
    computed: {
        setCurrentYear() {
            const date = new Date();
            return date.getFullYear();
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    color: #bbbbbb;
    font: {
        family: 'Roboto Condensed', sans-serif;
        size: 1.2rem;
    }
    padding: 18px 0;
    text-align: center;

	@media (width >= 768px) {
		font-size: 1.3rem;
	}

	@media (width >= 992px) {
		font-size: 1.4rem;
	}
}
</style>

<template>
	<div class="card" :class="cardType">
		<div class="card__topic">
			<img class="card__topic-img" :src="imagePath" :alt="imageAlt" width="140" loading="lazy">
		</div>
		<div class="card__wrap">
			<div class="card__header">
				<div class="card__stats">
					<span class="card__author" v-if="cardAuthor">{{ `by ${cardAuthor}` }}</span>
					<time class="card__time" :datetime="cardDate">{{ prettifyDate }}</time>
					<span class="card__read">{{ `${cardReadTime} Minute Read` }}</span>
				</div>
				<div class="card__title">{{ cardTitle }}</div>
			</div>
			<div class="card__body">
				<p class="card__intro">{{ introParagraph }}</p>
				<router-link class="card__link" :to="readingLink" @click="closeDrawer">Continue Reading This Post</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Card',
	props: {
		cardAuthor: String,
		cardDate: Number,
		cardFeatured: {
			default: false,
			type: Boolean
		},
		cardIntro: String,
		cardLink: String,
		cardReadTime: Number,
		cardTitle: String,
		cardTopic: String
	},
	computed: {
		...mapGetters(['menuState']),
		// @todo Remove this function
		capitalizeWord() {
			return `${this.cardTopic.charAt(0).toUpperCase()}${this.cardTopic.slice(1)}`;
		},
		cardType() {
			let className = `card--${this.cardTopic}`;

			if (this.cardFeatured) {
				className += ' card--featured';
			}
			return className;
		},
		imageAlt() {
			switch(this.cardTopic) {
				case 'css':
					return 'CSS image';
				case 'gulp':
					return 'Gulp image';
				case 'html':
					return 'HTML image';
				case 'javascript':
					return 'JavaScript image';
				case 'jenkins':
					return 'Jenkins logo featuring a butler with a blue, red, and white color scheme';
				case 'lambda':
					return 'Lambda image';
				case 'React':
					return 'React image';
				case 'vue':
					return 'Vue image';
				default:
					return 'General image'
			}
		},
		imagePath() {
			return require(`@/assets/logo_${this.cardTopic}.svg`);
		},
		introParagraph() {
			return this.cardIntro.split('\\n\\n')[1];
		},
		prettifyDate() {
			const dateFormat = new Date(this.cardDate * 1000).toDateString().split(' ');
			return `${dateFormat[1]} ${dateFormat[2]}, ${dateFormat[3]}`;
		},
		readingLink() {
			return `/details/${this.cardLink}`;
		}
	},
	methods: {
		...mapActions(['menuToggle']),
		closeDrawer() {
			if (this.menuState) this.menuToggle();
		}
	}
}
</script>

<style lang="scss" scoped>
.card {
	$self: &;
	background-color: var(--shark-dark);
	border-radius: 14px;

	&__author {
		display: none;
	}

	&__body {
		padding: 0 16px 18px;
	}

	&__header {
		padding: 18px 16px 0;
	}

	// @todo Test for browser compatibility, otherwise use Clamp.js
	&__intro {
		display: -webkit-box;
		font-size: 1.4rem;
		letter-spacing: .3px;
		line-height: 1.3;
		margin-top: 20px;
		overflow: hidden;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	&__link {
		align-items: center;
		display: flex;
		font-size: 1.2rem;
		font: {
			size: 1.2rem;
			weight: var(--weight-medium);
		}
		justify-content: flex-end;
		letter-spacing: .2px;
		margin: 16px 0 0 0;

		&:active,
		&:hover,
		&:link,
		&:visited {
			color: #cccccc;
		}

		&::after {
			background: transparent url('@/assets/icon_readmore.svg');
			content: '';
			display: block;
			height: 18px;
			margin-left: 4px;
			width: 18px;
		}
	}

	&__read {
		float: right;
	}

	&__stats {
		font-size: 1.1rem;
	}

	&__title {
		font: {
			size: 2rem;
			weight: var(--weight-medium);
		}
		line-height: 1.2;
		margin-top: 20px;
	}

	&__topic {
		align-items: center;
		border-radius: 14px 14px 0 0;
		display: flex;
		height: 234px;
		justify-content: center;
	}

	// Modifier(s)
	&--css {
		#{$self}__topic {
			background-color: #333333;
		}
	}

	&--gulp {
		#{$self}__topic {
			background-color: #cf4647;
		}
	}

	&--html {
		#{$self}__topic {
			background-color: #333333;
		}
	}

	&--javascript {
		#{$self}__topic {
			background-color: #f7d800;
		}
	}

	&--jenkins {
		#{$self}__topic {
			background-color: #1e496c;
		}
	}

	&--lambda {
		#{$self}__topic {
			background-color: #d86613;
		}
	}

	&--react {
		#{$self}__topic {
			background-color: #23272f;
		}
	}

	&--vue {
		#{$self}__topic {
			background-color: #1a1a1a;
		}
	}

	// Tablet and above viewports
	@media (width >= 768px) {
		&--featured {
			display: grid;
			grid-template: {
				areas:
					'wrap topic';
				columns: 60% 40%;
			}

			#{$self} {
				&__author {
					display: inline-block;
				}

				&__body {
					padding: 32px 20px 36px;
				}

				&__header {
					display: flex;
					flex-direction: column-reverse;
					padding: 36px 20px 0;
				}

				&__intro {
					-webkit-line-clamp: none;
					line-height: 1.4;
					margin-top: 0;
				}

				&__link {
					margin-top: 30px;
				}

				&__read {
					float: none;
				}

				&__stats {
					font-size: 1.2rem;
					margin-top: 12px;
				}

				&__time {
					margin: 0 16px;
				}

				&__title {
					font-size: 2.2rem;
					margin-top: 0;
				}

				&__topic {
					border-radius: 0 14px 14px 0;
					height: auto;
					grid-area: topic;
				}

				&__wrap {
					grid-area: wrap;
				}
			}

			/*@media (width >= 768px) {
				min-height: 360px;
			}*/
		}
	}

	@media (width >= 992px) {
		&--featured {
			#{$self} {
				&__intro {
					font-size: 1.6rem;
				}

				&__link {
					font-size: 1.4rem;
				}

				&__title {
					font-size: 2.3rem;
				}
			}
		}
	}
}
</style>

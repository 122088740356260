<template>
	<div class="ad">
		<p class="ad__text">ADVERTISEMENT</p>
		<!-- Home Banner -->
		<!--<ins
			class="adsbygoogle"
			style="display:block"
			data-ad-client="ca-pub-1893735116921446"
			data-ad-slot="4885838437"
			data-ad-format="auto"
			data-adtest="on"
			data-full-width-responsive="true"></ins>-->
	</div>
</template>

<script>
export default {
	name: 'Ad',
/*	created() {
		// Load Adsense script
		const adScript = document.createElement('script');
		adScript.async = true;
		adScript.crossOrigin = 'anonymous';
		adScript.setAttribute('google_adtest', 'on'); // Other attrs to try: `data-adtest` or `data-adbreak-test`
		adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1893735116921446';
		adScript.text = '(adsbygoogle = window.adsbygoogle || []).push({});';
		document.head.appendChild(adScript);
	},
	mounted() {
		// Initialize AdSense script and display the ad on the page
		const initScript = document.createElement('script');
		initScript.text = '(adsbygoogle = window.adsbygoogle || []).push({})';
		document.body.appendChild(initScript);
	}*/
}
</script>

<style lang="scss" scoped>
.ad {
	align-items: center;
	background-color: var(--mine-shaft);
	border-radius: 8px;
	color: #cccccc;
	display: flex;
	font-weight: var(--weight-semibold);
	height: 50px;
	//height: 100px;
	justify-content: center;
	letter-spacing: 1.5px;
	margin: 30px 0;
	//width: 300px;
}
</style>

<template>
    <header class="header">
        <Brand/>
        <MenuToggle/>
    </header>
	<MenuDrawer/>
</template>

<script>
import Brand from '@/components/Brand.vue';
import MenuDrawer from '@/components/MenuDrawer.vue';
import MenuToggle from '@/components/MenuToggle.vue';

export default {
    name: 'Header',
    components: {
		Brand,
		MenuDrawer,
        MenuToggle
    }
}
</script>

<style lang="scss" scoped>
.header {
    align-items: center;
    display: flex;
    height: 46px;
}

.menu-toggle {
    margin-left: auto;
}
</style>

<template>
	<div class="home" v-if="posts.length">
		<!-- Posts are sent in descending order from database -->
		<section class="featured">
			<Card
				:card-author="posts[0].Author"
				:card-date="posts[0].CreatedAt"
				:card-featured="isFeatured"
				:card-intro="posts[0].Content"
				:card-link="posts[0].PostId"
				:card-read-time="posts[0].ReadTime"
				:card-title="posts[0].Title"
				:card-topic="posts[0].Topic"/>
		</section>
		<Ad/>
		<!-- Using slice() to begin looping at index 1 -->
		<section class="latest">
			<Card
				v-for="(post, index) in posts.slice(1)"
				:key="index"
				:card-date="post.CreatedAt"
				:card-intro="post.Content"
				:card-link="post.PostId"
				:card-read-time="post.ReadTime"
				:card-title="post.Title"
				:card-topic="post.Topic"/>
		</section>
	</div>
	<!--
	@todo Use Suspense to help orchestrate async dependencies
	{@link https://vuejs.org/guide/built-ins/suspense.html}
	-->
	<div class="home" v-else>
		<div class="featured">
			<CardSkeleton modifier="featured"/>
		</div>
		<CardSkeleton modifier="space"/>
		<div class="latest">
			<CardSkeleton v-for="n in 10" :key="n"/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// @ is an alias to /src
import Ad from '@/components/Ad.vue';
import Card from '@/components/Card.vue';
import CardSkeleton from '@/components/Card-Skeleton.vue';

export default {
	name: 'Home',
	components: {
		CardSkeleton,
		Ad,
		Card
	},
	data() {
		return {
			isFeatured: true
		}
	},
	computed: {
		...mapGetters(['posts'])
	},
	async created() {
		await this.getPosts();
	},
	methods: {
		...mapActions(['getPosts'])
	}
}
</script>

<style lang="scss" scoped>
.latest {
	display: grid;
	row-gap: 30px;

	@media (width >= 768px) {
		grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
		column-gap: 20px;
	}
}
</style>

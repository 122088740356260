import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Import global components
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
//import Search from '@/components/Search.vue';

const app = createApp(App);

app.use(store);
app.use(router);

app.component('Header', Header);
app.component('Footer', Footer);
//app.component('Search', Search);

app.mount('#app');
